import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AppendScript } from "gatsby-theme-starberry-lomondgroup/src/common/utils/site_utlis";

import "gatsby-theme-starberry-lomondgroup/src/components/ClientReview/ClientReview.scss";
import ContentModule from "gatsby-theme-starberry-lomondgroup/src/modules/content_module";

import {Grid} from "@dadoagency/gatsby-plugin-trustpilot-widget";

const AllAgentReview = ({ Module }) => {


    return (
        <React.Fragment>
            <section className="client-reviews all-agent-review">
                <Container>
                    <Row>
                        <Col>
                            <div className="review-wrapper">
                                {Module.title && <h3>{Module.title}</h3> }
                                {Module.description && <ContentModule Content={Module.description} /> }
                                <div className="content-wrap">
                                    <Grid 
                                        language="en"
                                        culture="US"
                                        theme="light"
                                        width="100%"
                                        height="500px"
                                        stars="4,5"
                                    />
                                </div>
                            </div>
                          
                        </Col>
                    </Row>


                </Container>
            </section>
        </React.Fragment>
    );
};
export default AllAgentReview;
